import React, { useState, useEffect } from 'react';

const SumaMental = () => {
  const [config, setConfig] = useState({ 
    digitos: 1, 
    tiempo: 1,
    cantidad: 2,
    operacion: 'suma',
    evitarCero: true,
    evitarUnoEnMultiplicacion: true
  });
  const [mostrarOpciones, setMostrarOpciones] = useState(false);
  const [estado, setEstado] = useState('menu');
  const [numActual, setNumActual] = useState(0);
  const [resultado, setResultado] = useState(0);
  const [respuesta, setRespuesta] = useState(null);
  const [ejercicioActual, setEjercicioActual] = useState(1);
  const [numerosMostrados, setNumerosMostrados] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [primerNumero, setPrimerNumero] = useState(0);

  const containerStyle = {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#1E90FF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '48px',
    fontWeight: 'bold',
    cursor: 'pointer',
    padding: '20px',
    boxSizing: 'border-box',
  };

  const menuStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
    maxWidth: '400px',
    padding: '20px',
    boxSizing: 'border-box',
  };

  const menuItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '20px',
    fontSize: '20px',
    padding: '0 10px',
  };

  const inputStyle = {
    fontSize: '24px',
    width: '90px',
    backgroundColor: 'white',
    color: 'black',
    border: 'none',
    borderRadius: '5px',
    padding: '5px',
    textAlign: 'center',
  };

  const selectStyle = {
    ...inputStyle,
    width: '120px',
    cursor: 'pointer',
  };

  const emailLinkStyle = {
    fontSize: '16px',
    marginTop: '20px',
    color: 'white',
    textDecoration: 'none',
    opacity: 0.8,
    transition: 'opacity 0.2s ease',
    cursor: 'pointer',
    textAlign: 'center',
    width: '100%',
  };

  const messageContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    maxWidth: '600px',
    textAlign: 'center',
    padding: '20px',
  };

  const bigNumberStyle = {
    fontSize: '120px',
    transition: 'transform 0.1s ease-in-out',
    transform: animate ? 'scale(1.2)' : 'scale(1)',
    backgroundColor: animate ? 'rgba(255, 255, 255, 0.3)' : 'transparent',
    padding: '20px 40px',
    borderRadius: '20px',
    textAlign: 'center',
    marginBottom: '20px',
  };

  const buttonStyle = {
    fontSize: '24px',
    background: 'none',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    padding: '10px 20px',
    marginTop: '30px',
    textAlign: 'center',
    width: '100%',
  };

  const opcionesStyle = {
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    padding: '15px',
    marginTop: '10px',
    marginBottom: '20px',
  };

  const checkboxStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    marginBottom: '10px',
    cursor: 'pointer',
  };

  const generarNumero = (digitos) => {
    let numero;
    do {
      if (digitos === 1) {
        const min = config.evitarCero ? 1 : 0;
        const max = 9;
        numero = Math.floor(Math.random() * (max - min + 1)) + min;
      } else {
        const min = Math.pow(10, digitos - 1);
        const max = Math.pow(10, digitos) - 1;
        numero = Math.floor(Math.random() * (max - min + 1)) + min;
      }
    } while (
      (config.evitarCero && numero.toString().includes('0')) ||
      (config.evitarUnoEnMultiplicacion && config.operacion === 'multiplicacion' && numero === 1)
    );
    return numero;
  };

  const iniciarJuego = (e) => {
    e.stopPropagation();
    setEstado('mostrando');
    const nuevoNumero = generarNumero(config.digitos);
    setNumActual(nuevoNumero);
    setPrimerNumero(nuevoNumero);
    setResultado(nuevoNumero);
    setRespuesta(null);
    setEjercicioActual(1);
    setNumerosMostrados(0);
    setAnimate(true);
  };

  const siguienteNumero = () => {
    if (estado === 'mostrando') {
      if (numerosMostrados === 0) {
        setNumerosMostrados(1);
        const segundoNumero = generarNumero(config.digitos);
        setNumActual(segundoNumero);
        
        if (config.operacion === 'suma') {
          setResultado(primerNumero + segundoNumero);
        } else {
          setResultado(primerNumero * segundoNumero);
        }
        setAnimate(true);
      } else {
        setEstado('esperando');
      }
    }
  };

  const handleClick = () => {
    if (estado !== 'menu') {
      switch (estado) {
        case 'esperando':
          setRespuesta(resultado);
          setEstado('entre-ejercicios');
          break;
        case 'entre-ejercicios':
          if (ejercicioActual < config.cantidad) {
            setEjercicioActual(prev => prev + 1);
            setEstado('mostrando');
            const nuevoNumero = generarNumero(config.digitos);
            setNumActual(nuevoNumero);
            setPrimerNumero(nuevoNumero);
            setResultado(nuevoNumero);
            setNumerosMostrados(0);
            setRespuesta(null);
            setAnimate(true);
          } else {
            setEstado('finalizado');
          }
          break;
        case 'finalizado':
          setEstado('menu');
          setRespuesta(null);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (estado === 'mostrando') {
      const animationReset = setTimeout(() => {
        setAnimate(false);
      }, 100);

      const timer = setTimeout(siguienteNumero, config.tiempo * 1000);
      return () => {
        clearTimeout(timer);
        clearTimeout(animationReset);
      };
    }
  }, [estado, numActual, numerosMostrados, config.tiempo]);

  const getContent = () => {
    switch (estado) {
      case 'menu':
        return (
          <div style={menuStyle} onClick={(e) => e.stopPropagation()}>
            <div style={{fontSize: '48px', marginBottom: '30px', textAlign: 'center'}}>MENÚ</div>
            
            <div style={menuItemStyle}>
              <span>Operación</span>
              <select
                value={config.operacion}
                onChange={(e) => setConfig({...config, operacion: e.target.value})}
                style={selectStyle}
                onClick={(e) => e.stopPropagation()}
              >
                <option value="suma">Suma</option>
                <option value="multiplicacion">Multiplicación</option>
              </select>
            </div>

            <div style={{...menuItemStyle, cursor: 'pointer'}} onClick={() => setMostrarOpciones(!mostrarOpciones)}>
              <span style={{fontSize: '16px'}}>Opciones avanzadas {mostrarOpciones ? '▼' : '▶'}</span>
            </div>

            {mostrarOpciones && (
              <div style={opcionesStyle}>
                <label style={checkboxStyle}>
                  <input
                    type="checkbox"
                    checked={config.evitarCero}
                    onChange={(e) => setConfig({...config, evitarCero: e.target.checked})}
                    style={{marginRight: '10px'}}
                  />
                  Quitar el 0 de sumas y multiplicaciones
                </label>
                <label style={checkboxStyle}>
                  <input
                    type="checkbox"
                    checked={config.evitarUnoEnMultiplicacion}
                    onChange={(e) => setConfig({...config, evitarUnoEnMultiplicacion: e.target.checked})}
                    style={{marginRight: '10px'}}
                  />
                  Quitar el 1 de multiplicaciones
                </label>
              </div>
            )}

            <div style={menuItemStyle}>
              <span>Número de dígitos</span>
              <input 
                type="number" 
                value={config.digitos} 
                onChange={(e) => setConfig({...config, digitos: Math.max(1, parseInt(e.target.value) || 1)})}
                style={inputStyle}
                onClick={(e) => e.stopPropagation()}
              />
            </div>

            <div style={menuItemStyle}>
              <span>Tiempo en segundos</span>
              <input 
                type="number" 
                value={config.tiempo} 
                onChange={(e) => setConfig({...config, tiempo: Math.max(1, parseInt(e.target.value) || 1)})}
                step="1"
                style={inputStyle}
                onClick={(e) => e.stopPropagation()}
              />
            </div>

            <div style={menuItemStyle}>
              <span>¿Cuántos ejercicios?</span>
              <input 
                type="number" 
                value={config.cantidad} 
                onChange={(e) => setConfig({...config, cantidad: Math.max(1, parseInt(e.target.value) || 1)})}
                style={inputStyle}
                onClick={(e) => e.stopPropagation()}
              />
            </div>

            <button 
              style={buttonStyle}
              onClick={iniciarJuego}
            >
              Haz clic aquí para comenzar
            </button>
            
            <a 
              href="mailto:oscar.salas@dataplus.com.pe"
              style={emailLinkStyle}
              onClick={(e) => e.stopPropagation()}
              onMouseEnter={(e) => e.target.style.opacity = '1'}
              onMouseLeave={(e) => e.target.style.opacity = '0.8'}
            >
              Creado por oscar.salas@dataplus.com.pe
            </a>
          </div>
        );
      case 'mostrando':
        return (
          <div style={messageContainerStyle}>
            <div style={bigNumberStyle}>{numActual}</div>
          </div>
        );
      case 'esperando':
        return (
          <div style={messageContainerStyle}>
            Haz clic para ver la respuesta
          </div>
        );
      case 'entre-ejercicios':
        return (
          <div style={messageContainerStyle}>
            <div style={{marginBottom: '20px'}}>Respuesta</div>
            <div style={bigNumberStyle}>{respuesta}</div>
            <div style={buttonStyle}>
              Haz clic para la siguiente {config.operacion === 'suma' ? 'suma' : 'multiplicación'}
            </div>
          </div>
        );
      case 'finalizado':
        return (
          <div style={messageContainerStyle}>
            MENÚ
          </div>
        );
      default:
        return '';
    }
  };

  return (
    <div style={containerStyle} onClick={handleClick}>
      {getContent()}
    </div>
  );
};

export default SumaMental;